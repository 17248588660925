import styled from "styled-components";
import apiPaths from "../../services/apPaths";

const ProductPageDesign = styled.div`
  /* @font-face {
    font-family: "Graphik";
    src: url("./fonts/Graphik-Regular.woff2") format("woff2"),
      url("./fonts/Graphik-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik-light";
    src: url("./fonts/Graphik-Light.woff2") format("woff2"),
      url("./fonts/Graphik-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Graphik-bold";
    src: url("./fonts/Graphik-Semibold.woff2") format("woff2"),
      url("./fonts/Graphik-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  } */

  .background-gradient-svg {
    position: fixed;
    inset: 0;
    z-index: -1;

    .animated-gradients__svg {
      width: 100%;
      height: 100%;
    }
  }
   
  h2 {
    font-family: "Graphik-light";
    font-weight: normal;
  }

  .second_vedio_section .col-md-12 {
    padding: 0;
  }
  .product-section-banner {
    display: block;
    float: left;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: ${(props) =>
    `url(${apiPaths.DOC_URL.MEDIA_URL + props.bannerImage})`};
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 48.2%;
    margin-top: 7vh;
    @media (max-width: 1199px) {
      padding-bottom: 60%;
      margin-top: 70px;
    }
    @media (max-width: 992px) {
      padding-bottom: 120%;
    }
    @media (max-width: 767px) {
      margin-top: 110px; 
      padding-bottom: 155%;
    }
    iframe {
      width: 240vw;
      height: 120%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 767px){
        width: 280vw;
      }
    }
    &:before {
      content: "";
      background: rgb(207, 167, 121);
      background: -moz-linear-gradient(
        180deg,
        rgba(207, 167, 121, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(207, 167, 121, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(207, 167, 121, 0) 0%,
        rgba(0, 0, 0, 0.7) 100%
      );
      filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#cfa779", endColorstr="#000000", GradientType=1);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 480px;
      z-index: 1;
    }

    .Content-banner {
      position: absolute;
      bottom: 40px;
      z-index: 99;
      color: #fff;
      width: 100%;
      max-width: 1100px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);

      p {
        font-size: 27px;
        line-height: 32px;
        max-width: 660px;
        margin: auto;
        font-family: "Graphik";
        font-weight: 300;
        @media (max-width: 1280px) {
          font-size: 24px;
        }
        @media (max-width: 991px) {
          font-size: 20px;
          padding: 0 15px;
        }
        @media (max-width: 767px) {
          font-size: 17px;
        }
      }
      h2 {
        font-size: 106px;
        font-weight: 100;
        font-family: "Graphik-light";
        text-transform: uppercase;
        @media (max-width: 1280px) {
          font-size: 90px;
        }
        @media (max-width: 991px) {
          font-size: 70px;
        }
        @media (max-width: 767px) {
          font-size: 50px;
        }
      }
    }
     /* 06-04-2023 */
  }

  .discount_image {
    /* position: absolute;
    top: 33px;
    left: 50%;
    z-index: 99;
    width: 100%;
    transform: translateX(-50%); */
    /* margin-top: 99px; */

    
    @media only screen and (max-width:1024px) {
      /* margin-top: 112px; */
    }

    img {
      width: 100%;
    }

    @media (min-width: 768px){
      .for_mob{
        display: none;
      }
    }

    @media (max-width: 767px){
      img:not(.for_mob){
        display: none;
      }
    }

    @media (max-width: 1200px) {
      top: 29px;
    }

    @media (max-width: 1199px) {
      top: 14px;
    }

    @media (max-width: 1199px) {
      top: 41px;
    }
    
    @media (max-width: 1199px) {
      top: 2px;
    }
  }

  .product-section-slider > {
    p {
      text-align: center;
      font-family: "Graphik-light";
      font-size: 14px;
    }
  }

  .price_section {
    div {
      font-size: 18px;
      /* font-family: "Graphik-bold"; */
      min-inline-size:max-content;
    }
    .strickClass {
      font-size: 20px;
      color: #6a6a6a;
      font-family: "Graphik";
      text-align: right;
    }
  }
  .product-section-slider {

    &.discount_image_active {
      .container {
        /* margin-top:50px; */
        
        @media only screen and (max-width:991px) {
          /* margin-top:20px; */
        }
        @media only screen and (max-width:767px) {
          /* margin-top:0px; */
        }
      }
    }
    .biotickakire-video-sec {
    padding: 70px 0 0;
    clear: both;

    @media only screen and (max-width:767px) {
      padding: 40px 0 0;
    }

    .container {
      margin: 0 auto;
    }

    .biotickakire-video-wrap {
      text-align: center;

      h2 {
        margin: 0 0 30px;

        @media only screen and (max-width:767px) {
          margin: 0 0 20px;
        }
      }

      .biotickakire-video-innerwrap {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        max-width: 709px;
        margin: auto;

        @media only screen and (max-width:767px) {
          gap: 20px;
        }
        @media only screen and (max-width:575px) {
          flex-direction: column;
          align-items: center;
        }

        .biotickakire-video {
          width: calc(100%/3 - calc(60px/3));
          height: 386px;
          border-radius: 20px;
          overflow: hidden;

          @media only screen and (max-width:767px) {
            height: 300px;
            width: calc(100%/3 - calc(40px/3));
          }
          @media only screen and (max-width:575px) {
            width: 216px;
            height: 386px;
          }

          iframe {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
    .title_section > .green_code {
      position: absolute;
      bottom: 10px;
      left: 0;
      padding-left: 10px;
      color: green;
      font-size: 12px;
      font-family: "Lato";
    }
    .kaire_live_longer {
      .container {
        @media only screen and (max-width: 767px) {
          margin-top: 40px;
        }
      }
      h3 {
        font-size: 37px;
        font-family: "Graphik-light";
        color: #000;
        text-transform: uppercase;
        margin-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 30px;
        }
      }
      p {
        line-height: 28px;
        color: #000;
      }
    }
    .product-section-details {
      padding: 40px 0 20px;
      float: left;
      width: 100%;

      .container {
        margin-top: 0;

        @media only screen and (max-width: 1199px) {
          margin-top:70px;
        }

        @media only screen and (max-width: 991px) {
          margin-top:30px;
        }

        @media only screen and (max-width: 991px) {
          margin-top:10px;
        }
      }
    }
    .container {
      max-width: 1000px !important;
      width: 100% !important;
      min-width: auto;
      margin-top:105px;

      @media only screen and (max-width: 1199px) {
        margin-top:70px;
      }
      
      @media only screen and (max-width: 767px) {
        margin-top:30px;
      }
    }
  
    .product_detail_container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        &>div{
          width: auto;
        }
      }
    }
    .product-image {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }

      img {
        width: 100%;
        max-width: 385px;
  
  
        @media (max-width: 767px) {
          margin: 0 auto;
          display: block;
          max-width: 200px;
        }
      }      
    } 
    .second_section {
      .radio_class {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #969696;
        min-height: 75px;
        color: #000;
        padding-right: 40px;

        @media (max-width: 767px) {
          padding-right: 10px;
        }
        &:first-child {
          border-top: 1px solid #969696;
        }
      }
      .active {
        background: hsl(216deg 66% 24% / 7%);
      }
    }
    .radio_class {
      &:hover {
        background: hsl(216deg 66% 24% / 7%);
        box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
      }
      &.selected_class {
        background: hsl(216deg 66% 24% / 7%);
        // box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
      }
    }
    .custom_radio {
      svg {
        path {
          fill: #00356a;
        }
      }
    }

    h3 {
      margin: 0 0 2px;
      font-size: 17px;
      /* font-family: "Graphik-bold"; */
    }
    p {
      margin-bottom: 0;
      font-size: 17px;
      line-height: normal;
      font-family: "Graphik";
      .title_section > .green_code {
        color: green;
        font-size: 12px;
      }
    }
    .title_section {
      width: 100%;
      padding-left: 10px;
    }
    .quantity_section.box > {
      span {
        display: none;
      }
    }
    .quantity_section {
      margin-top: 25px;
      margin-bottom: 21px;
    }
    div#qty {
      max-width: 165px;
      display: flex;
      justify-content: space-between;
      margin: auto;
      > {
        button {
          background: none;
          font-size: 32px;
          color: #000;
          height: 50px;
          line-height: normal;
          max-width: 50px;
          width: 100%;
          flex: 50px;
          max-width: 200px;
          padding: 5px 0 0;
          transition: 0.3s ease all;
          &:hover {
            background: #f5f4fd;
            color: #00356a;
          }
        }
        input[type="text"] {
          max-width: 50px;
          text-align: center;
          height: 50px;
          background: #dddddd;
          border: none;
          color: #000;
          font-size: 21px;
          font-weight: 600;
        }
        button {
          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
    .first_section {
      margin: 0 0 30px 0;
      text-align: center;
      h2.title {
        margin: 0;
        color: #000;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 32px;
          text-align: center;
        }
      }
      p {
        text-align: center;
        font-size: 22px;
        font-family: "Graphik-light";
        color: #000;
        letter-spacing: 0.5px;
        @media (max-width: 767px) {
          font-size: 15px;
        }
      }
    }
    .custom-cont {
      p.green_code {
        bottom: 15px;
      }
      span.discount_smartship {
        bottom: 12px;
        font-size: 12px;
      }
      .second_section .radio_class {
        padding-right: 13px;
      }
    }
  }

  .accordian_section {
    margin: 0 auto;
    width: 60%;
  }

  .frequently_product_section {
    .frequestion-section {
      text-align: center;
      .product-checkout {
        ul {
          display: flex;
          justify-content: center;
          list-style: none;
          @media (max-width: 767px) {
            flex-wrap: wrap;
          }
          li {
            max-width: 320px;
            margin: 0px 20px;
            width: 100%;
            @media (max-width: 767px) {
              max-width: 100%;
              margin: 0px auto;
            }
            a {
              margin: 0;
            }
            svg {
              &.down_icon {
                color: #c9c9c9;
                font-size: 50px;
                margin-top: -30px;
              }
              &.active {
                color: #153566;
              }
            }
            &:last-child {
              @media (max-width: 767px) {
                margin-top: 20px;
              }
            }
          }
        }
      }
      .second_section {
        .custom_radio {
          padding-right: 10px;
          .title_section {
            text-align: left;
            width: 100%;
            h3 {
              font-size: 18px;
              text-transform: capitalize;
              font-family: "Graphik-bold";
              margin-bottom: 0;
            }
          }
          .price_section {
            div {
              font-size: 24px;
              padding-right: 0;
              font-family: "Graphik-bold";
              @media (max-width: 1280px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
  
  .main_section_unique {
    background: url(/images/bg-product.png) no-repeat;
    background-size: cover;
    min-height: 762px;
    width: 100%;
    padding-top: 115px;
    display: flex;
    justify-content: center;
    z-index: 0;
    position: relative;
    .image_and_title {
      max-width: 595px;
      display: flex;
      @media (max-width: 991px) {
        max-width: 100%;
      }
      @media (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
      }
      img {
        width: 100%;
        @media (max-width: 767px) {
          max-width: 170px;
          margin: 0 auto;
        }
      }
    }
    .btn-sec.Add_to_cart {
      margin-top: 10px;
      @media (max-width: 767px) {
        gap: 10px;
      }
      button {
        border-radius: 30px;
        height: 50px;
        font-family: Graphik;
        max-width: 205px;
        margin: 0px;
        background: rgb(255, 255, 255);
        border: 1px solid rgb(0, 53, 106);
        color: rgb(0, 53, 106);
        letter-spacing: 1px;
        transition: all 0.3s ease 0s;
        &:hover {
          background: rgb(0, 53, 106);
          color: rgb(255, 255, 255);
        }
      }
    }
    .see_more {
      width: 100%;
      list-style: none;
    }
    .btn-sec.Add_to_cart > * {
      flex: 1;
      max-width: calc(100% / 2);
    }
  }

  .strickClass {
    text-decoration: line-through;
  }
  .third_section {
    .btn-sec.Add_to_cart {
      flex-wrap: wrap;
      button[type="button"] {
        /* max-width: 100%; */
        &:hover {
          background: #fff;
          color: #00356a;
        }
      }
    }
  }

  .btn-sec.Add_to_cart {
    margin: 0;
    display: flex;

    button {
      border-radius: 30px;
      height: 50px;
      font-family: "Graphik";
      letter-spacing: 1px;
      max-width: 205px;
      margin: 0px;
      background: #fff;
      border: 1px solid #00356a;
      color: #00356a;
      letter-spacing: 1px;
      transition: 0.3s ease all;
    }
  }

  .review-section-now {
    text-align: center;
    padding: 20px 0 0;
    ul {
      justify-content: center;
      list-style: none;
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 5px;
      @media (max-width: 767px) {
        justify-content: space-between;
      }
      li:not(:nth-last-child(2)) {
        margin-right: 15px;
        @media (max-width: 767px) {
          margin-right: 0;
        }
      }
      li {
        font-size: 17px;
        font-family: "Graphik-light";
        a {
          font-size: 17px;
          color: #000;
          font-family: "Graphik-light";
        }
        span {
          font-size: 20px;
          &.breaker {
            margin: 0 4px;
            @media (max-width: 767px) {
              margin: 0 11px 0 0;
            }
          }
        }
      }
      p {
        font-size: 19px;
      }
    }
    .supplement-fact {
      text-align: center;
    }
  }

  .supplement-fact {
    text-align: center;
    button {
      color: #738092;
      height: 28px;
      width: 100%;
      max-width: 158px;
      border: 1px solid #abb2bb;
      border-radius: 20px;
      font-size: 15px;
      align-items: center;
      font-family: "Graphik";
      padding: 0;
      letter-spacing: normal;
      line-height: normal;
      text-transform: capitalize;
      &:first-child {
        margin-right: 10px;
      }
      &:hover {
        background: #00356a;
        color: #fff;
      }
    }
  }

  .accordion-title {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    .icon-accor {
      position: absolute;
      left: 0;
      font-size: 30px;
      font-weight: 100;
      line-height: 17px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 20px;
    }
    .accord-head {
      font-size: 26px;
      line-height: 52px;
      text-transform: uppercase;
      font-family: "Graphik-light";
    }
    * {
      transition: 0.3s ease all;
    }
    &:hover * {
      color: #eed7d7;
    }
  }
  .accordion-item {
    @media (max-width: 767px) {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .clearfix {
    clear: both;
  }

  .accordian_section {
    width: 100%;
    /* padding: 40px 0; */
    position:relative;
    padding-bottom: 75px;
    /* bottom: 75px; */

    .container {
      margin-top: 30px;
    }

    @media only screen and (max-width: 1199px) {
      padding-bottom: 75px
    }

    @media only screen and (max-width: 767px) {
      padding-bottom: 30px
    }
  }

  .accordion-content {
    font-family: "Graphik";
    font-size: 17px;
    line-height: 1.5;
    color: #000;
    margin-bottom: 15px;
    letter-spacing: 0.2px;
    ul ul {
      margin-bottom: 15px;
    }
    p,
    ul * {
      font-size: 17px;
      font-family: "Graphik";
      color: #000;
      line-height: 28px;
      letter-spacing: 0.1px;
    }
    p:not(:last-child) {
      margin-bottom: 25px;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
    ul {
      margin-left: 18px;
      margin-bottom: 40px;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }

  .frequently_product_section {
    background: #fff;
    padding: 45px 0;

    .container {
      margin-top: 0;
    }

    .frequestion-section {
      h3 {
        font-size: 37px;
        font-family: "Graphik-light";
        margin-bottom: 30px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 32px;
        }
      }
    }
    .frequestion-section {
      .product-checkout {
        ul {
          margin-bottom: 0;
        }
        button.btn.btn-cart {
          border-radius: 30px;
          height: 50px;
          font-family: "Graphik";
          letter-spacing: 1px;
          margin-top: 20px;
          color: #fff;
          max-width: 205px;
          width: 100%;
          font-size: 16px;
          border: 1px solid #00356a;
          transition: 0.3s ease all;
          &:hover {
            background: #fff;
            color: #00356a;
          }
        }
      }
      a.thumbnail {
        text-decoration: none;
        border: none;
        &:hover {
          color: #153566;
        }
        h5 {
          font-size: 27px;
          text-transform: uppercase;
          font-family: "Graphik-light";
          color: #000;
        }
        img {
          max-width: 150px;
          margin: 0 auto;
          width: 100%;
          min-height: 235px;
          object-fit: contain;
        }
      }
    }
  }

  .kaire_live_longer {
    padding: 1px 60px 20px;
    clear: both;
    @media (max-width: 1199.98px) {
      padding: 50px 15px 20px;
    }
    @media (max-width: 767px) {
      .container {
        padding: 0;
      }
      padding: 30px 15px 20px;
    }
  }
  .second_vedio_section {
    overflow: hidden;
    /* padding-bottom: 80px; */
    /* padding-top: 10px; */
    figure.parallax-outer.custom-class {
      margin-top: 60px;
    }
  }
  .our-unique {
    max-width: 1138px;
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 9;
    overflow: hidden;
    box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.3);
    height: 639px;
    margin-bottom: -190px;
    margin-top: 40px;
    background: ${(props) =>
    props?.section2Image &&
    `url(${apiPaths.DOC_URL.MEDIA_URL + props?.section2Image})`};
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: 1199.98px) {
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      height: 559px;
      margin-top: 100px;
    }
    @media (max-width: 1024px) {
      max-width: 900px;
      height: 509px;
      position: relative;
    }
    @media (max-width: 991px) {
      max-width: 700px;
      height: 395px;
      margin-top: 50px;
    }
    @media (max-width: 767px) {
      height: 380px;
      max-width: 100%;
    }
    @media (max-width: 578px) {
      height: 280px;
      margin-top: 0;
    }

    iframe {
      width: 100%;
      height: 640px;
      @media (max-width: 1199.98px) {
        width: 100%;
        height: 560px;
      }
      @media (max-width: 1024px) {
        height: 509px;
      }
      @media (max-width: 991px) {
        max-width: 700px;
        height: 101%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @media (max-width: 767px) {
        max-width: 140vw;
        width: 140vw;
        height: 500px;
      }
    }
  }

  .point_section {
    h2 {
      font-size: 30px;
      color: #fff;
      text-transform: uppercase;
      text-align: left;
      margin-top: 0;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
    ul {
      list-style-position: outside;
      padding-left: 17px;
    }
    li {
      color: #fff;
      line-height: 31px;
      font-size: 17px;
      font-family: "Graphik";
      margin-bottom: 10px;
    }
    @media (max-width: 991px) {
      padding: 40px 15px;
    }
    h3 {
      color: #fff;
      font-size: 24px;
    }
    p.see_benifits {
      margin-top: 30px;
      text-decoration: underline;
      text-underline-offset: 3px;
      @media (max-width: 767px) {
        text-align: center;
        line-height: 1.5;
      }
    }
  }

  .product-section-slider {
    .imageText {
      h3 {
        font-size: 30px;
        /* font-family: "Graphik-Light"; */
        color: #fff;
        line-height: 47px;
        text-transform: uppercase;
        @media (max-width: 1199.98px) {
          font-size: 24px;
          line-height: 1.6;
        }
        @media (max-width: 991px) {
          text-align: center;
        }
      }
    }
    .main_section_unique {
      .container {
        max-width: 1140px !important;
        min-width: auto;
        width: 100%;
      }
      .image_and_title {
        align-items: flex-start;
        @media (max-width: 991px) {
          align-items: center;
        }
      }
      @media (max-width: 767px) {
        padding-top: 195px;
      }
    }
    .for_desktop_view,
    .for_mobile_view {
      a {
        background: #fff;
        width: 100%;
        max-width: 205px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border-radius: 30px;
        /* margin: 0 auto; */
        margin: 20px 0 0;
        border: 1px solid #00356a;
        color: #00356a;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: "Graphik";
        text-decoration: none;
        transition: 0.3s ease all;
        &:hover {
          background: #00356a;
          color: #fff;
        }
      }
      @media (max-width: 767px) {
        margin-top: 40px;
      }
    }
  }

  .main_section_unique {
    .point_section {
      max-width: 482px;
      flex: 482px;
      @media (max-width: 991px) {
        max-width: 100%;
        flex: 100%;
      }
    }
    .col-md-6 {
      &:first-child {
        max-width: calc(100% - 482px);
        flex: calc(100% - 482px);
        width: 100%;
        @media (max-width: 991px) {
          max-width: 100%;
          flex: 100%;
        }
      }
    }
  }

  .customer-review-now {
    padding: 45px 0 0;
    .container {
      max-width: 1140px !important;
      min-width: auto;
      width: 100%;
    }
  }

  .review-customer {
    h5 {
      font-size: 25px;
      font-family: "Graphik-light";
      color: #000;
      margin: 0 0 10px;
    }
    h2 {
      text-align: left;
      font-size: 51px;
      color: #000;
      margin-top: 0;
      @media (max-width: 767px) {
        font-size: 35px;
      }
    }
  }

  .client-div {
    padding: 20px 0;
    border-bottom: 1px solid #919191;
    h6 {
      font-size: 17px;
      margin: 0;
      line-height: 26px;
      font-family: "Graphik-bold";
      color: #000;
      font-weight:600;
    }
    .comment {
      p {
        margin-bottom: 0;
        font-size: 17px;
        font-family: "Graphik";
        color: #000;
      }
    }
  }

  .comment-stars {
    i {
      color: #000;
    }
    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  .main-qus {
    background: rgb(21 53 102 / 10%);
    padding: 20px 0;
    min-height: 150px;
    display: flex;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 1400px) {
      margin-top: 50px;
    }
    .question-text {
      max-width: 675px;
      margin: 0 auto;
    }
    .stay-touch-part {
      margin-left: 0;
      max-width: 100%;
      h5 {
        margin: 0;
        font-size: 25px;
        font-family: "Graphik-light";
        text-transform: uppercase;
        color: #000;
        text-align: left;
      }
    }
    form.form-inline {
      justify-content: flex-start;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      label.col-form-label {
        font-size: 45px;
        text-align: left;
        font-family: "Graphik-light";
        font-weight: 100;
        text-transform: uppercase;
        color: #000;
        @media (max-width: 767px) {
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
      .form-group {
        max-width: calc(100% - 139px);
        flex: calc(100% - 139px);
        padding-right: 20px;
        @media (max-width: 767px) {
          flex: 100%;
          max-width: 100%;
        }
        input#staticEmail {
          max-width: calc(100% - 260px);
          flex: calc(100% - 260px);
          @media (max-width: 767px) {
            flex: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }

  .form-subscribe {
    .btn {
      width: 100%;
      max-width: 139px;
      flex: 139px;
      font-size: 13px;
      height: 33px;
      text-transform: uppercase;
      font-family: "Graphik";
      background: #000000;
      border: 1px solid #000000;
      transition: 0.3s ease all;
      &:hover {
        background: #fff;
        color: #00356a;
      }
    }
  }

  .footer-text-sec {
    text-align: center;
    padding: 43px 0 62px;
    @media (max-width: 1280px) {
      margin: 40px 0;
    }
    @media (max-width: 1199.98px) {
      padding: 0;
    }
  }

  .box-text-bnner {
    img {
      width: 100%;
      max-width: 500px;
    }
    h4 {
      font-size: 17px;
      color: #000;
      font-family: var(--common-font);
      margin-top: 30px;   
       font-style: italic;
    }
  }

  .btn-pre-view {
    text-align: center;
    button {
      max-width: 100px;
      width: 100%;
      background: #00356a;
      border-radius: 30px;
      color: #fff;
      text-align: center;
      font-family: "Graphik";
      text-transform: uppercase;
      font-size: 13px;
      padding: 0;
      transition: 0.3s ease all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 33px;
      border: 1px solid #00356a;
      cursor: pointer;
      &:hover {
        background: #fff;
        color: #00356a;
      }
    }
    .Next-btn {
      margin-left: 20px;
    }
    button.disabled_button {
      background: #919191;
      border: 1px solid #919191;
      pointer-events: none;
    }
  }

  .container.mt-5 {
    margin-top: 50px;
  }
  .add_button_div {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .btn-sec.Add_to_cart {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      button {
        white-space: nowrap;
        border-radius: 30px;
        height: 50px;
        font-family: "Graphik";
        max-width: 100%;
        margin: 25px auto 0px;
        background: rgb(0, 53, 106);
        border: 1px solid rgb(0, 53, 106);
        color: rgb(255, 255, 255);
        letter-spacing: 1px;
        transition: all 0.3s ease 0s;
        &:hover {
          background: rgb(255, 255, 255);
          color: rgb(0, 53, 106);
        }
      }
      .btn-sec {
        .Add_to_wishList {
          margin-top: 20px;
        }
      }
    }
  }


/* -----27Aug2024---- */
  .custom-cont h2.title {
    font-size: 30px;
}
.custom-cont .second_section .title_section h3.order {
    font-weight: 600 !important;
}
.custom-cont .second_section .price_section div {
    font-weight: 600;
}
.product-section-details .third_section .btn-sec.Add_to_cart button {
    width: 100%;
}

.product-section-details .fourth_section a.par-logo {
    display: flex;
    max-width: 100px;
    justify-content: center;
    margin: 10px auto 0;
    color: #000;
}

.product-section-details .fourth_section a.par-logo img {
    width: 100%;
    max-width: 60px;
    height: 60px;
}

.product-section-details .fourth_section a.par-logo p span {
    width: 25px;
    height: 25px;
    background: var(--blue);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: 900;
    margin: 0 auto 5px;
}

.product-section-details .fourth_section a.par-logo p {
    font-size: 14px;
    text-align: center;
}
.product-checkout .title_section h3.order, .product-checkout .price_section  {
    font-weight: 600;
}
.form-subscribe {
  max-width: 100%;
  margin: 0;
  text-align: center;
}
form.form-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.form-subscribe .form-inline .form-group {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;
  max-width: calc(100% - 250px);
  flex: calc(100% - 250px);
}
.form-subscribe label {
  margin-bottom: 0;
  line-height: normal;
}

.form-subscribe .form-inline .form-group label.col-form-label {
  max-width: 260px;
  flex: 260px;
  padding:0;
}

.form-subscribe .form-inline .form-group input#staticEmail {
  max-width: calc(100% - 350px);
  flex: calc(100% - 350px);
}

input#staticEmail {
  border: none;
  background: none;
  margin: 0 0 0 0px;
  border-bottom: 1px solid #000;
  width: 100%;
  font-size: 17px;
}

.form-subscribe .btn {
  background: #000;
  border: none;
  border-radius: 25px;
  width: 123px;
  height: 38px;
  font-size: 17px;
  margin-top: 10px;
  line-height: normal;
}
.price-sec .footer-merge {
  max-width: 380px;
  width: 100%;
}
.price_section_footer .radio_section span.MuiButtonBase-root {
    color: rgb(25, 118, 210);
}
.price_section_footer .title_section h3.order, .price_section_footer .price_section div  {
    font-weight: 600;
}
.main-qty-sec {
  margin: 0 0 15px;
  display: flex;
}

`;
export default ProductPageDesign;

export const ProductPageFooter = styled.div`
   & .price_section_footer {
    width: 100%;
    overflow-x: hidden;
    background-color: #fff;
    background-color: rgb(255 255 255 / 80%);
    backdrop-filter: blur(10px);
    padding: 0 15px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    @media (max-width: 991px) {
      padding: 0 5px;
    }
    .footer-merge {
      float: left;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .price-sec {
      display: flex;
      @media (max-width: 991px) {
        flex-wrap: wrap;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .add_button_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .btn-sec.Add_to_cart {
      width: 100%;
      button {
        background: #00356a;
        color: #fff;
      }
      .btn-sec {
        .Add_to_wishList {
          margin-top: 20px;
        }
      }
    }

    .navbar-right {
      margin-right: 0;
    }
  }
  .review_list {
    margin-right: 10px;
  }

  .select {
    select {
      border-radius: 30px;
      font-family: "Graphik";
    }
  }
  .price-inner-sec {
    display: flex;
    .second_section {
      display: flex;
      .radio_class {
        display: flex;
        align-items: center;
        min-height: 75px;
        color: #000;
        padding-right: 40px;
        border-top: 1px solid #969696;
        border-bottom: 1px solid #969696;
        margin: 0 10px;
        &:hover {
          background: hsl(216deg 66% 24% / 7%);
          box-shadow: 0 0 5px 2px rgb(0 0 0 / 10%);
        }
        &.selected_class {
          background: hsl(216deg 66% 24% / 7%);
        }
      }
      .active {
        background: hsl(216deg 66% 24% / 7%);
      }
      .custom_radio {
        padding-right: 10px;
        .title_section {
          text-align: left;
          h3 {
            font-size: 18px;
            text-transform: capitalize;
            /* font-family: "Graphik-bold"; */
            margin-bottom: 2px;
          }
          span.discount_smartship {
            bottom: 8px;
            font-size: 12px;
          }
        }
        .price_section {
          div {
            padding-right: 0;
            @media (max-width: 1280px) {
              font-size: 18px;
            }
            @media (max-width: 991px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .footer-merge {
    .quality {
      margin: 0;

      .main-qty-sec {
        margin: 0px;
      }
      div#qty {
        /* border-radius: 30px;  */
        max-width: 100px;
        justify-content: space-between;
        display: flex;
        font-family: "Graphik";
        border: none;
      }
      input {
        font-family: "Graphik-bold";
        width: 40px;
        flex: 40px;
      }
    }
  }
  .price_section_footer {
    .price-sec {
      max-width: 1360px;
    }
    .price-inner-sec {
      max-width: calc(100% - 380px);
      flex: calc(100% - 380px);
      align-items: center;

      .second_section .custom_radio .title_section {
        display: flex;
        align-items: center;
        h3 {
          padding-right: 10px;
        }
      }
      @media (max-width: 1280px) {
        max-width: calc(100% - 300px);
        flex: calc(100% - 300px);
      }
      @media (max-width: 991px) {
        max-width: 100%;
        flex: 100%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        flex: 100%;
      }
      .price {
        margin: 0;
        flex-wrap: wrap;
      }
      h5.bonus_value {
        white-space: nowrap;
        margin: 0 15px;
      }
    }
    .footer-merge {
      .Add_to_cart {
        button[type="button"] {
          margin: 0;
          font-family: "Graphik";
          font-weight: normal;
          font-size: 15px;
          padding: 10px;
          /* max-width: 100%; */
          text-transform: initial;
          @media (max-width: 1280px) {
            font-size: 14px;
            padding: 10px;
            max-width: 155px;
          }
          &:hover {
            background: #fff;
            color: #00356a;
          }
        }
      }
      @media (max-width: 1280px) {
        max-width: 270px;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        flex: 100%;
        justify-content: center;
      }
    }
  }

  .main-qty-sec {
    margin: 0;
    .box {
      span {
        font-family: "Graphik-bold";
      }
      &:last-child {
        max-width: 100%;
        flex: 1 1 100%;
      }
    }
  }

  .price_section_footer .price-sec {
    justify-content: space-between;
    margin: auto;
  }
  .cart-add {
    .btn-sec.Add_to_cart {
      .Add_to_cart {
        width: 100%;
      }
    }
  }
  li.logout_icon {
    svg {
      margin-top: 0;
    }
  }
  .footer-merge {
    .btn-sec {
      .Add_to_wishList {
        margin-top: 0px;
      }
    }
  }
  nav.navbar.navbar-default .upper_lower_div ul.nav.navbar-nav.navbar-top {
    padding-left: 0;
  }
  .footer-merge {
    .cart-add {
      width: 100%;
      flex: 100%;
      .btn-sec.Add_to_cart {
        justify-content: space-between;
      }
      @media (max-width: 1024px) {
        max-width: 160px;
      }
    }
  }

  .price_section_footer {
    .price-inner-sec {
      .second_section {
        width: 100%;
        .radio_class.selected_class,
        .custom_radio {
          border: none;
          margin: 0;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          max-width: 50%;
          width: 100%;
        }
        .custom_radio {
          border-left: 0;
          justify-content: space-between;
          .title_section {
            width: 100%;
            h3,
            p {
              margin: 0;
              font-size: 14px;
              text-align: left;
              @media (max-width: 991px) {
                font-size: 12px;
                line-height: normal;
              }
            }
            p {
              line-height: 30px;
              @media (max-width: 991px) {
                margin-top: 5px;
              }
            }
          }

          .price_section span.discount_smartship {
            bottom: 16px;
            font-size: 12px;
            line-height: normal;
          }
          p.green_code {
            font-size: 12px;
          }
        }
      }
    }
    .footer-merge {
      .main-qty-sec {
        .box {
          #qty {
            max-width: 100%;
            flex: 100%;
            input {
              background: #eef0f4;
              max-width: 60px;
              flex: 60px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  
  .supplement-fact {
    @media (max-width: 767px) {
      display: flex;
    }
    .upper_lower_div {
      ul.navbar-top {
        li {
          a {
            padding-top: 0;
            padding-bottom: 0;
            text-decoration: none;
          }
        }
      }
    }
  }
  h3.see_benifits {
    color: #fff;
  }
 
  .main-qty-sec .box:last-child {
  display: flex;
  align-items: center;
}

.main-qty-sec .box:last-child span {
  max-width: 42%;
  flex: 0 0 42%;
  -ms-flex: 0 0 42%;
}

.main-qty-sec .box:last-child #qty {
  max-width: 70%;
  flex: 0 0 70%;
  -ms-flex: 0 0 70%;
}
.main-qty-sec .box #qty {
  position: relative;
  border: 1px solid var(--blue);
  align-items: center;
}

.main-qty-sec .box #qty input {
  width: 20%;
  border: 0;
  height: 37px;
  text-align: center;
  background: #fff;
}
.main-qty-sec {
  margin: 0 -15px 15px;
  display: flex;
}

.main-qty-sec .box {
  padding: 0 15px;
  max-width: 50%;
  flex: 0 0 50%;
  -ms-flex: 0 0 50%;
}
.main-qty-sec .box #qty button {
  /* position: absolute; */
  top: 0;
  bottom: 0;
  width: 22px;
  height: 37px;
  background: transparent;
  color: #000;
  border: 0;
  padding: 0;
}
.price_section_footer .footer-merge button {
    width: 100%;
}
.main-qty-sec .box {
  justify-content: space-between;
}

.price_section_footer .footer-merge .main-qty-sec .box #qty {
    flex-wrap: nowrap;
    margin: 0;
}
 `;
export const DialogSectionCss = styled.div`
 [class*="ActiveStyleComp"] > .shipping_address {
   display: flex;
   align-items: center;
   gap: 10px;
   h5 {
     margin: 0;
   }
 }
 label {
   width: 100%;
   text-align: center;
   margin-bottom: 10px;
   font-size: 20px;
   text-decoration: underline;
 }

 .content_container {
   display: flex;
   width: 100%;
   flex-wrap: nowrap;
   flex-direction: row;
   justify-content: space-between;
   background: #f1f1f1;
   border-radius: 5px;
   padding: 0;
   & > * {
     flex: 100%;
     padding: 10px;
   }
   .shipping_address .billing_address,
   .shipping_address {
     display: flex;
     flex-direction: column;
     max-width: 45%;
     align-items: flex-start;
     h6 {
       font-size: 16px;
       font-weight: 600;
       margin: 0;
     }
     span {
       word-break: break-all;
     }
   }
   .billing_address {
     h6 {
       font-size: 16px;
       margin: 0 0;
       font-weight: 600;
     }
   }
 }
 table {
   width: 100%;
   margin-top: 20px;
   tbody {
     border: 1px solid #ddd;
   }
   tr {
     td {
       padding: 12px 10px;
       text-align: center;
     }
     th {
       padding: 12px 10px;
       border: 1px solid #fff;
       text-align: center;
       color: #fff;
       background-color: #16356a;
     }
   }
 }
`;
